.photoContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: #ffffff; /*Fallback Support*/
    background-color: var(--lightdarkBG);
}

.photoDisplay {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    top: 111px;
    left: 0;
    right: 0;
    padding: 0 10px 40px 10px;
    line-height: 0;
    text-align: left;
}

.copyright {
    position: fixed;
    background-color: #1d74d5; /*Fallback Support*/
    background-color: var(--mainColorRGBA-Opacity95);
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 0 0 20px 20px;
    min-width: 210px;
    width: 80%;
    font-size: 13px;
    line-height: 14px;
	font-weight: 100;
    padding: 5px 0;
}

@media screen and (min-width: 375px) {
    .photoDisplay {
        grid-template-columns: repeat(2, 1fr);
        padding: 0 20px 40px 20px;
    }
}

@media screen and (min-width: 650px) {
    .photoDisplay {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 1000px) {
    .photoDisplay {
        grid-template-columns: repeat(4, 1fr);
        padding: 0 30px 40px 30px;
    }
}

@media screen and (min-width: 1600px) {
    .photoDisplay {
        grid-template-columns: repeat(5, 1fr);
        padding: 0 40px 40px 40px;
    }
}

@media screen and (min-width: 1650px) {
    .photoDisplay {
        grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
    }
}

@media screen and (min-width: 875px) {

    .photoDisplay {
        top: 108px
    }

    .copyright {
        left: auto;
        right: 9px;
        bottom: 7px;
        transform: none;
        border-radius: 20px;
        max-width: 190px;
    }
    

}