.photo {
    width: 100%;
    height: auto;
    transition: 0.5s ease-in;
}

.photos:hover .photo {
    border-radius: 75px;
    cursor: pointer;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    transition: 3s ease-out;
}

.imageOverlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000000;
    z-index: 1000;
    padding: 20px;
    overflow-y: hidden;
}

.bgImage {
    background-size: cover;
    background-position: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    filter: alpha(opacity=40); /* For IE8 and earlier */
    opacity: 0.4;
    filter: blur(10px);
    -webkit-filter: blur(10px);
    z-index: 1001;
}

.largeImageContainer {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    min-height: 100px;
    max-width: 1000px;
    z-index: 1002;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.largeImageContainer img {
    max-width: 100%;
    max-height: calc(100vh - 130px);
    min-height: 175px;
}

.navButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    text-shadow: 0px 0px 2px  #000000;
    font-size: 60px;
    padding: 0;
    opacity: 0.5;
}

.navButton:hover {
    opacity: 0.9;
    filter: alpha(opacity=90); /* For IE8 and earlier */
}

.closeImage {
    top: 0;
    right: 0;
    line-height: 25px;
    text-align: right;
    width: 50px;
    height: 50px;
    z-index: 1004;
}

.backNext {
    top: 0;
    bottom: 0;
    z-index: 1003;
}

.prevPhoto {
    left: 0;
    width: 30%;
    text-align: left;
}

.nextPhoto {
    right: 0;
    width: 70%;
    text-align: right;
}

.infoContainer {
    position: relative;
    z-index: 1002;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.infoContainer h1 {
    font-weight: 400;
    font-size: 35px;
    line-height: 30px;
    padding: 5px 0;
}

.infoContainer h2 {
    font-weight: 100;
    font-size: 14px;
    line-height: 18px
}

@media screen and (min-width: 375px) {

    .infoContainer h1 {
        font-weight: 100;
        font-size: 40px;
        line-height: 35px;
        padding-top: 10px;
    }

    .infoContainer h2 {
        font-size: 18px;
        line-height: 22px
    }

}

@media screen and (min-width: 600px) {

    .infoContainer h1 {
        padding-top: 20px;
    }

    .infoContainer h2 {
        font-size: 16px;
        line-height: 20px;
    }

}

@media screen and (min-width: 1030px) {

    .largeImageContainer {
        max-width: 1600px;
    }

    .navButton {
        padding: 0;
    }

}