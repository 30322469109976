.home {
    display: flex;
    flex-flow: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.photoHome {
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center center;
    background-position: 50% 55%;
    /* padding:50px; */
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: relative;
}

.photoHome .logo {
    position: absolute;
	width: auto;
    height: auto;
    max-width: 80%;
	max-height: 70%;
}

.featuredSets {
    flex: 0 auto;
    width: 100%;
    padding-bottom: 10px;
    background-color: var(--mainColorHex)
}

.featuredSets:hover .featuredContainer {
    opacity: 0.5;
}

.featuredContainer {
    width: 45%;
    padding: 12px 5px 0 5px;
    display: inline-block;
    position: relative;
}

.featuredSets:hover .featuredContainer:hover {
    opacity: 1;
}

.featuredContainer img {
    max-width: 100%;
    max-height: 100%;
}

.featuredContainer h2 {
    font-size: 15px;
    font-weight: 100;
    letter-spacing: 2px;
}

@media screen and (min-width: 500px) {
    .featuredContainer {width: 30%;}
}
@media screen and (min-width: 1000px) {
    .featuredContainer {width: 15%; max-width: 300px}
}