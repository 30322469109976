:root {
  --mainColorHex: #1d74d5;
  --mainColorRGBA-Opacity95: rgba(33,116,211, 0.95);
  --secondColorHex: #ffffff;
  --lightAccentHex: #7fa7d4;
  
  /************** Light/Dark Mode Colors *******************/
  --lightdarkBG: #000000;
}

@media (prefers-color-scheme: light) {:root {--lightdarkBG: #ffffff;}}

/* width */
::-webkit-scrollbar {
  width: 5px;
  z-index: 40
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightdarkBG);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--mainColorHex);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--secondColorHex);
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Crimson Pro', serif;
  color: #ffffff;
  text-align: center
}

a {
	text-decoration: none
}

body {
    background-color: #ffffff; /*Fallback Support*/
    background-color: var(--lightdarkBG);
    overflow-x: hidden;
}
